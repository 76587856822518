import React from "react";
import "./footer.scss";
import logo from "../../asset/utama/cls-logoc.webp";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-CLS" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h2>Marketing Gallery</h2>
            <h1>CITRALAND SURABAYA</h1>
            <p>Surabaya, East Java</p>
          </div>
          <div className="contact">Contact us : +6285940970345(Cathlin)</div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">CITRALAND SURABAYA</div>
    </div>
  );
};

export default footer;
